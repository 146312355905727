@tailwind components;
 @tailwind utilities;
 @tailwind base;
 @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@600&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

 :root {
  --primary-dark: #5651e5;
  --primary-light:#709dff;
}

@layer base {
  /* h1 {
     font-family: oswald, sans-serif;
    font-style: normal;
    font-weight: 900; 
    
    /* font-family: mono45-headline, monospace;
    font-style: normal;
    font-weight: 700; */

    /* font-family: railroad-gothic-atf, sans-serif;
font-style: normal;
font-weight: 800; }  */
  li,p
  {
    font-family: buena-park-jf, serif;
font-style: normal;
font-weight: 700;


  }

  html{
    @apply scroll-smooth
  }
  li{
    @apply p-4 text-sm
  }
  button {
    @apply px-4 py-2 border bg-gradient-to-r from-[var(--primary-dark)] to-[var(--primary-light)] text-white
  }
  h2 {
    @apply text-xl font-semibold;
    font-family: 'Bebas Neue', cursive;

  }
  .head{
    font-family: 'Bebas Neue', cursive;
  }
  .but{
    font-family: 'Bebas Neue', cursive;
  }
  .about{
    
    font-family: 'Noto Serif JP', serif;
    font-weight: bold;
  }
  .li-items{
    font-family: 'Bebas Neue', cursive;
  }
  .font-style-text {

    font-weight: bold; 
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    
   

  }
  
}